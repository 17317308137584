import { lazyLoad } from "../utils/lazyLoad";
import { startWatch } from "../utils/startWatch";
const initLazyLoad = () => {
    startWatch({
        elements: document.querySelectorAll("[data-lazy]"),
        handler: (entry) => {
            if (entry.isIntersecting) {
                lazyLoad(entry.target);
            }
        },
        margin: "40% 0% 40%",
    });
};
initLazyLoad();
