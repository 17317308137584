var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ymapKey } from "../const";
import Map from "../libs/Map";
function getPopupDataByPoint(points) {
    var _a, _b, _c;
    const result = {};
    const names = [];
    result.params = [];
    for (let i = 0; i < points.length; i++) {
        let dataJson = (_a = points[i]) === null || _a === void 0 ? void 0 : _a.getAttribute('data-params');
        if (!dataJson)
            return;
        const data = JSON.parse(dataJson);
        names.push((_b = data.name) !== null && _b !== void 0 ? _b : '');
        if ((data.type === 'SERVICE_CENTER' || data.type === 'OFFICE' || points.length === 1) && !result.params.length) {
            let items = (_c = points[i].closest('.city-row')) === null || _c === void 0 ? void 0 : _c.querySelectorAll('.contact-item');
            if (items) {
                items.forEach((item) => {
                    var _a;
                    (_a = result.params) === null || _a === void 0 ? void 0 : _a.push(item.cloneNode(true));
                });
            }
        }
    }
    result.name = names.join(' | ');
    return result;
}
const contactsRedesigned = () => __awaiter(void 0, void 0, void 0, function* () {
    const regionsList = document.querySelector("#regions-list");
    if (!regionsList)
        return;
    const mapContainer = document.querySelector("#contacts-map");
    const offices = regionsList.querySelectorAll('[data-office-item]');
    const regions = regionsList.querySelectorAll('tab-toggle[data-region-id]');
    if (!mapContainer || !offices || !regions)
        return;
    yield Map.loadAPI(ymapKey);
    const map = new Map({
        container: mapContainer,
        center: [37.678095153807625, 55.55714242655886],
        zoom: 10,
    });
    const markers = [];
    offices.forEach((office) => {
        if (office.getAttribute('data-coords') == null)
            return;
        const dataCoords = office.getAttribute('data-coords');
        const split = dataCoords.split(",");
        const coords = [parseFloat(split[1]), parseFloat(split[0])];
        const markerContent = Map.createDefaultMarker();
        markerContent.addEventListener("click", function () {
            var _a, _b, _c;
            map.setLocation(coords);
            const currentRegion = (_a = regionsList.querySelector(`tab-toggle[active]`)) === null || _a === void 0 ? void 0 : _a.getAttribute('panel');
            let region = (_b = office.closest('tab-panel[name]')) === null || _b === void 0 ? void 0 : _b.getAttribute('name'); //tab-panel[name=region-37]
            if (!region)
                return;
            if (region !== currentRegion) {
                let parentToggleTab = regionsList.querySelector(`tab-toggle[panel="${region}"]`);
                parentToggleTab === null || parentToggleTab === void 0 ? void 0 : parentToggleTab.click();
            }
            const points = regionsList.querySelectorAll(`[data-coords="${dataCoords}"]`);
            points === null || points === void 0 ? void 0 : points.forEach((point) => {
                var _a;
                (_a = point === null || point === void 0 ? void 0 : point.classList) === null || _a === void 0 ? void 0 : _a.add('open');
            });
            const isPopup = (_c = this.closest('.marker-container')) === null || _c === void 0 ? void 0 : _c.querySelector('.popup');
            if (isPopup) {
                isPopup.style.display = 'block';
            }
            setTimeout(function () {
                points === null || points === void 0 ? void 0 : points.forEach((point) => {
                    var _a;
                    (_a = point === null || point === void 0 ? void 0 : point.classList) === null || _a === void 0 ? void 0 : _a.remove('open');
                });
            }, 1000 * 2);
        });
        office.addEventListener("click", () => {
            map.setLocation(coords);
        });
        const points = regionsList.querySelectorAll(`[data-coords="${dataCoords}"]`);
        const markerWidthPopup = Map.createMarkerWidthPopup(markerContent, getPopupDataByPoint(points));
        map.addMarker(coords, markerWidthPopup);
        markers.push({
            content: markerWidthPopup,
            id: office.id,
        });
    });
    regions.forEach((region) => {
        var _a;
        const regionId = region.getAttribute('panel');
        const regionPanel = regionsList.querySelector(`tab-panel[name='${regionId}']`);
        if (!regionId || !regionPanel)
            return;
        const dataCoords = (_a = regionPanel.querySelector('[data-coords]')) === null || _a === void 0 ? void 0 : _a.getAttribute('data-coords');
        if (!dataCoords)
            return;
        const split = dataCoords.split(",");
        const coords = [parseFloat(split[1]), parseFloat(split[0])];
        region.addEventListener("click", () => {
            map.setLocation(coords);
        });
    });
});
contactsRedesigned();
