import { QueryObserver } from "@tanstack/vue-query";
import { queryClient } from "../vue/queryClient";
import { updateBasket } from "../vue/basketState";
const headerCart = () => {
    const cartContainers = document.querySelectorAll("[data-cart] [data-count]");
    if (!cartContainers)
        return;
    const observer = new QueryObserver(queryClient, {
        queryKey: ["basketData"],
        queryFn: () => updateBasket(),
    });
    observer.subscribe((result) => {
        if (result.status != "success")
            return;
        let count = 0;
        result.data.items.forEach((product) => {
            if (!product.DELAYED)
                count++;
        });
        cartContainers.forEach((container) => {
            container.innerText = count > 0 ? count.toString() : "";
        });
    });
};
headerCart();
