var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
export let TabPanel = class TabPanel extends LitElement {
    constructor() {
        super(...arguments);
        this.active = false;
        this.name = '';
    }
    render() {
        return html `
      <slot></slot>
    `;
    }
};
__decorate([
    property({
        type: Boolean,
        reflect: true
    })
], TabPanel.prototype, "active", void 0);
__decorate([
    property({
        type: String,
        reflect: true
    })
], TabPanel.prototype, "name", void 0);
TabPanel = __decorate([
    customElement('tab-panel')
], TabPanel);
