"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
const initCitySelectForm = () => __awaiter(void 0, void 0, void 0, function* () {
    const container = document.querySelector("#city-select");
    if (!container)
        return;
    if (!container.dataset.params)
        return;
    const { createApp } = yield import("vue");
    const { default: CitySelectForm } = yield import("./CitySelectForm.vue");
    const params = JSON.parse(container.dataset.params);
    const app = createApp(CitySelectForm, params);
    app.mount(container);
});
initCitySelectForm();
