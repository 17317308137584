var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
export let TabGroup = class TabGroup extends LitElement {
    constructor() {
        super();
        this.tabToggles = [];
        this.tabPanels = [];
        this.isChildren = false;
        const tabs = this.querySelectorAll('tab-toggle');
        tabs.forEach(tab => this.tabToggles.push(tab));
        const panels = this.querySelectorAll('tab-panel');
        panels.forEach(panel => this.tabPanels.push(panel));
    }
    handleClick(event) {
        const target = event.target;
        const newTab = target.closest('tab-toggle');
        const tabGroup = newTab === null || newTab === void 0 ? void 0 : newTab.closest('tab-group');
        if (tabGroup !== this || newTab === null) {
            return;
        }
        this.tabPanels.forEach(panel => panel.active = false);
        this.tabToggles.forEach(tab => tab.active = false);
        const newPanel = this.tabPanels.find(panel => panel.name == newTab.panel);
        if (!newPanel)
            return;
        this.setActiveTab(newTab, newPanel);
    }
    setActiveTab(newTab, newPanel) {
        newTab.active = true;
        newPanel.active = true;
    }
    getDefaultTemplate() {
        return html `
      <div part="nav" @click=${this.handleClick}>
        <slot name="nav"></slot>
      </div>
      <div part="panels">
        <slot name="panels"></slot>
      </div>
    `;
    }
    getChildrenTemplate() {
        return html `
      <div part="nav" @click=${this.handleClick}>
        <slot name="nav"></slot>
      </div>
      <div part="panels">
        <slot name="panels"></slot>
        <slot name="children"></slot>
      </div>
    `;
    }
    getTemplate() {
        return this.isChildren ? this.getChildrenTemplate() : this.getDefaultTemplate();
    }
    render() {
        return this.getTemplate();
    }
};
__decorate([
    property({
        type: Boolean,
        reflect: true
    })
], TabGroup.prototype, "isChildren", void 0);
TabGroup = __decorate([
    customElement('tab-group')
], TabGroup);
