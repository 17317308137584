var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ymapKey } from "../const";
import Map from "../libs/Map";
const contactsFilter = () => __awaiter(void 0, void 0, void 0, function* () {
    const regionsList = document.querySelector("#regions-list");
    if (!regionsList)
        return;
    const regionFilter = document.querySelector("#region-filter");
    const typeFilter = document.querySelector("#type-filter");
    const mapContainer = document.querySelector("#contacts-map");
    if (!regionFilter || !typeFilter || !mapContainer)
        return;
    let currentRegion = "all";
    let currentType = "all";
    const offices = Array.from(regionsList.children);
    yield Map.loadAPI(ymapKey);
    const map = new Map({
        container: mapContainer,
        zoom: 4,
    });
    const markers = [];
    offices.forEach((office) => {
        if (!office.dataset.coords)
            return;
        const head = office.querySelector("[slot=head]");
        if (!head)
            return;
        const split = office.dataset.coords.split(",");
        const coords = [parseFloat(split[1]), parseFloat(split[0])];
        const markerContent = Map.createDefaultMarker();
        markerContent.addEventListener("click", () => office.slideOpen());
        map.addMarker(coords, markerContent);
        head.addEventListener("click", () => map.setLocation(coords));
        markers.push({
            content: markerContent,
            id: office.id,
        });
    });
    const filter = () => {
        offices.forEach((office) => {
            const marker = markers.find((current) => current.id == office.id);
            let show = false;
            if (currentRegion == "all" || currentRegion == office.dataset.regionId)
                show = true;
            if (currentType != "all" && currentType != office.dataset.type)
                show = false;
            if (show) {
                marker && marker.content.classList.remove("hidden");
                office.classList.remove("hidden");
            }
            else {
                marker && marker.content.classList.add("hidden");
                office.classList.add("hidden");
            }
        });
    };
    const changeFilter = (update) => {
        if (update == "region")
            currentRegion = regionFilter.value;
        else
            currentType = typeFilter.value;
        filter();
    };
    regionFilter.addEventListener("update-value", () => changeFilter("region"));
    typeFilter.addEventListener("update-value", () => changeFilter("type"));
});
contactsFilter();
