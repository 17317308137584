import MicroModal from 'micromodal';
const diff = window.innerWidth > document.documentElement.clientWidth ? 0 : window.innerWidth - document.documentElement.clientWidth;
const applyOffset = offset => {
    document.body.style.paddingRight = `${offset}px`;
};
function modalShow() {
    applyOffset(diff);
}
function modalClose(modal) {
    modal.addEventListener('animationend', () => applyOffset(0), { once: true });
}
export const modalConfig = {
    onShow: modalShow,
    onClose: modalClose,
    awaitOpenAnimation: true,
    awaitCloseAnimation: true,
    disableFocus: true,
    disableScroll: true
};
MicroModal.init(modalConfig);
