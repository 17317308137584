import { delegate } from "../utils/delegate";
delegate('click', '[data-anchor]', (e, anchor) => {
    e.preventDefault();
    if (!anchor.hash)
        return;
    const header = document.querySelector('.header');
    const target = document.querySelector(anchor.hash);
    if (!target || !header)
        return;
    window.scrollBy({
        behavior: 'smooth',
        top: target.offsetTop - window.scrollY - header.clientHeight - 50
    });
});
