var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import debounce from 'lodash/debounce';
const productSlider = () => __awaiter(void 0, void 0, void 0, function* () {
    const container = document.querySelector('#product-slider');
    if (!container)
        return;
    const thumbsContainer = container.querySelector('.thumbs');
    const thumbsSwiper = thumbsContainer === null || thumbsContainer === void 0 ? void 0 : thumbsContainer.querySelector('.swiper');
    const mainSwiper = container.querySelector('.main-swiper');
    if (!thumbsContainer || !thumbsSwiper || !mainSwiper)
        return;
    const { default: Swiper } = yield import('../libs/Swiper');
    const thumbs = new Swiper(thumbsSwiper, {
        slidesPerView: 3,
        direction: 'horizontal',
        breakpoints: {
            1280: {
                direction: 'vertical',
                slidesPerView: 5
            },
            992: {
                slidesPerView: 3
            },
            768: {
                slidesPerView: 5
            },
            576: {
                slidesPerView: 4
            },
        }
    });
    const main = new Swiper(mainSwiper, {
        speed: 550,
        navigation: {
            prevEl: thumbsContainer.querySelector('.button-slider-arrow.prev'),
            nextEl: thumbsContainer.querySelector('.button-slider-arrow.next')
        },
        thumbs: {
            swiper: thumbs
        }
    });
    const onResize = () => {
        const isMobile = matchMedia('(max-width: 1279px)').matches;
        isMobile ? thumbs.changeDirection('horizontal') : thumbs.changeDirection('vertical');
    };
    window.addEventListener('resize', debounce(onResize, 300));
});
productSlider();
