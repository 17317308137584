import { getFavourite } from "../vue/favouriteState";
import { QueryObserver } from "@tanstack/vue-query";
import { queryClient } from "../vue/queryClient";
const headerFavourites = () => {
    const favouritesContainers = document.querySelectorAll("[data-favourite] [data-count]");
    if (!favouritesContainers)
        return;
    const observer = new QueryObserver(queryClient, {
        queryKey: ["favouritesData"],
        queryFn: getFavourite,
    });
    observer.subscribe((result) => {
        if (result.status != "success")
            return;
        favouritesContainers.forEach((container) => {
            container.innerText = result.data.length > 0 ? result.data.length.toString() : "";
        });
    });
};
headerFavourites();
