import MicroModal from 'micromodal';
import { modalConfig } from '../components/modal';
import { delegate } from '../utils/delegate';
import { queryClient } from '../vue/queryClient';
import { setCurrentProduct } from '../vue/currentProduct';
delegate('click', ('[data-quick-order]'), (e, btn) => {
    MicroModal.show('modal-quick-order', modalConfig);
    const id = btn.dataset.quickOrder;
    const name = btn.dataset.name;
    if (!name || !id)
        return;
    setCurrentProduct({
        id: parseInt(id),
        name
    });
    queryClient.invalidateQueries({
        queryKey: ['currentProduct']
    });
});
delegate('click', ('[data-get-price]'), (e, btn) => {
    MicroModal.show('modal-get-price', modalConfig);
    const id = btn.dataset.getPrice;
    const name = btn.dataset.name;
    if (!name || !id)
        return;
    setCurrentProduct({
        id: parseInt(id),
        name
    });
    queryClient.invalidateQueries({
        queryKey: ['currentProduct']
    });
});
