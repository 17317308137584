var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
export let CustomAccordeon = class CustomAccordeon extends LitElement {
    constructor() {
        super(...arguments);
        this.open = false;
    }
    onSchedule(fn) {
        requestAnimationFrame(function () {
            requestAnimationFrame(function () {
                fn();
            });
        });
    }
    slideOpen() {
        var _a;
        const content = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('[part="content"]');
        if (!content)
            return;
        content.style.height = `${content.scrollHeight}px`;
        this.onSchedule(() => {
            this.open = true;
            content.addEventListener("transitionend", () => (content.style.height = ""), {
                once: true,
            });
        });
    }
    slideClose() {
        var _a;
        const content = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('[part="content"]');
        if (!content)
            return;
        content.style.height = `${content.scrollHeight}px`;
        this.onSchedule(() => {
            this.open = false;
            content.style.height = "";
        });
    }
    slideToggle() {
        this.open ? this.slideClose() : this.slideOpen();
    }
    render() {
        return html `
			<slot name="head" @click=${this.slideToggle}></slot>
			<div part="content">
				<slot name="content"></slot>
			</div>
		`;
    }
};
CustomAccordeon.styles = css `
		:host::part(content) {
			overflow: hidden;
			transition: height var(--collapse-time, 0.8s);
			box-sizing: border-box;
		}
		:host(:not([open]))::part(content) {
			height: 0;
		}
	`;
__decorate([
    property({
        type: Boolean,
        reflect: true,
    })
], CustomAccordeon.prototype, "open", void 0);
CustomAccordeon = __decorate([
    customElement("custom-accordeon")
], CustomAccordeon);
