import { delegate } from "../utils/delegate";
const catalogMenu = () => {
    const menuWrap = document.querySelector('#catalog-menu .wrap');
    if (!menuWrap)
        return;
    const clickCloseMenu = (event) => {
        if (menuWrap.contains(event.target))
            return;
        close();
    };
    const escapeCloseMenu = (event) => {
        if (event.key != 'Escape')
            return;
        close();
    };
    const close = () => {
        document.body.classList.remove('catalog-menu-opened');
        document.removeEventListener('click', clickCloseMenu);
        document.removeEventListener('keyup', escapeCloseMenu);
    };
    const open = () => {
        document.body.classList.add('catalog-menu-opened');
        document.addEventListener('click', clickCloseMenu);
        document.addEventListener('keyup', escapeCloseMenu);
    };
    delegate('click', '[data-catalog-open]', (e, btn) => {
        const isOpen = document.body.classList.contains('catalog-menu-opened');
        isOpen ? close() : open();
    });
};
catalogMenu();
