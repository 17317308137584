var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
export let CountInput = class CountInput extends LitElement {
    constructor() {
        super(...arguments);
        this.value = 1;
    }
    dispatchChange() {
        this.dispatchEvent(new CustomEvent('update-value', {
            bubbles: true,
            detail: {
                value: this.value
            }
        }));
    }
    setValue(value) {
        if (this.max && value > this.max) {
            this.value = this.max;
        }
        else if (this.min && value < this.min) {
            this.value = this.min;
        }
        else
            this.value = value;
        this.dispatchChange();
    }
    increment() {
        this.setValue(this.value + 1);
    }
    decrement() {
        this.setValue(this.value - 1);
    }
    onChange(event) {
        const target = event.target;
        this.value = parseInt(target.value);
    }
    render() {
        return html `
    <button tabindex="-1" @click=${this.decrement}>
      <slot name="minusIcon"></slot>
    </button>
    <input @change=${this.onChange} disabled type="text" value="${this.value}"/>
    <button tabindex="-1" @click=${this.increment}>
      <slot name="plusIcon"></slot>
    </button>
    `;
    }
};
CountInput.styles = css `
    :host {
      display: flex;
      justify-content: space-between;
      border: 2px solid var(--border-color);
      border-radius: var(--border-radius);
    }
    button{
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      flex: 0 0 30%;
      background: transparent;
      border: 0;
      cursor: pointer;
    }
    input{
      flex: 1 1 auto;
      background: transparent;
      border: 0;
      padding: 0;
      min-width: 0;
      outline: none;
      font-family: inherit;
      text-align: center;
      font-size: var(--font-size);
      color: var(--color);
      line-height: 1.2;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button,
    input[type=number] {
      -webkit-appearance: none;
      -moz-appearance: textfield;
      appearance: none;
    }
  `;
__decorate([
    property({ type: Number })
], CountInput.prototype, "value", void 0);
__decorate([
    property({ type: Number })
], CountInput.prototype, "min", void 0);
__decorate([
    property({ type: Number })
], CountInput.prototype, "max", void 0);
CountInput = __decorate([
    customElement('count-input')
], CountInput);
