import { slideToggle } from "../libs/Accordeon";
import { delegate } from "../utils/delegate";
const initAccordeon = () => {
    delegate('click', '[data-accordeon-toggle]', (e, toggle) => {
        const accordeon = toggle.closest('[data-accordeon]');
        if (!accordeon)
            return;
        const content = accordeon.querySelector('[data-accordeon-content]');
        if (!content)
            return;
        accordeon.classList.toggle('active');
        slideToggle(content);
    });
};
initAccordeon();
