"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
const mainSlider = () => __awaiter(void 0, void 0, void 0, function* () {
    const section = document.querySelector('.main-slider');
    const swiper = section === null || section === void 0 ? void 0 : section.querySelector('.swiper');
    if (!swiper || !section)
        return;
    const { default: Swiper } = yield import('../libs/Swiper');
    new Swiper(swiper, {
        speed: 750,
        loop: true,
        navigation: {
            prevEl: swiper.querySelector('.prev'),
            nextEl: swiper.querySelector('.next')
        },
        pagination: {
            el: section.querySelector('.slider-pagination'),
            clickable: true
        }
    });
});
mainSlider();
