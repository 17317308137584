var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html } from "lit";
import { customElement, property } from "lit/decorators.js";
export let CustomSelect = class CustomSelect extends LitElement {
    constructor() {
        super();
        this.options = [];
        this.value = "";
        this.valueText = "";
        this.placeholder = "";
        this.active = false;
        const options = this.querySelectorAll("select-option");
        options.forEach((option) => {
            this.options.push(option);
            console.log(option.value, option.textContent, option.active);
            if (option.active) {
                this.value = option.value;
                this.valueText = option.textContent || "";
            }
        });
    }
    toggle() {
        this.active = !this.active;
    }
    dispatchChange() {
        this.dispatchEvent(new CustomEvent("update-value", {
            bubbles: true,
            cancelable: true,
            composed: true,
            detail: {
                value: this.value,
            },
        }));
    }
    handleClick(event) {
        const target = event.target;
        const newOption = target.closest("select-option");
        const customSelect = newOption === null || newOption === void 0 ? void 0 : newOption.closest("custom-select");
        if (customSelect !== this || newOption === null) {
            return;
        }
        this.options.forEach((option) => (option.active = false));
        newOption.active = true;
        this.value = newOption.value;
        this.valueText = newOption.innerText;
        this.active = false;
        this.dispatchChange();
    }
    render() {
        return html `
			<div part="box" @click=${this.toggle}>
				<div part="current-value">${this.valueText ? this.valueText : this.placeholder}</div>
				<slot name="box"></slot>
			</div>
			<div part="values" @click=${this.handleClick}>
				<slot></slot>
			</div>
		`;
    }
};
__decorate([
    property({
        type: String,
        reflect: true,
    })
], CustomSelect.prototype, "value", void 0);
__decorate([
    property({
        type: String,
    })
], CustomSelect.prototype, "valueText", void 0);
__decorate([
    property({
        type: String,
        reflect: true,
    })
], CustomSelect.prototype, "placeholder", void 0);
__decorate([
    property({
        type: Boolean,
        reflect: true,
    })
], CustomSelect.prototype, "active", void 0);
CustomSelect = __decorate([
    customElement("custom-select")
], CustomSelect);
