var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
const ajaxCatalog = () => __awaiter(void 0, void 0, void 0, function* () {
    const link = document.querySelector('[data-ajax-update-link]');
    const sortCatalog = document.querySelector('#sort-catalog');
    if (!link && !sortCatalog)
        return;
    const { default: Pjax } = yield import(/* webpackChunkName: "pjax-api" */ 'pjax-api');
    new Pjax({
        areas: ['#products-container, #pagination-container'],
        filter: (link) => {
            return link.matches('[data-ajax-update-link]');
        }
    });
    document.addEventListener('customSelectChanged', (e) => {
        const event = e;
        if (event.detail.dropdownID != 'sort-dropdown')
            return;
        const btn = document.querySelector('.btn-submit');
        if (btn === null || btn === void 0 ? void 0 : btn.href)
            Pjax.replace(btn.href, {
                areas: ['#products-container, #pagination-container']
            });
    });
});
ajaxCatalog();
export {};
