export default class Burger {
    constructor(node) {
        this.toggleState = () => {
            if (this.currentState == "closed")
                this.open();
            else
                this.close();
        };
        this.classOpened = "burger--opened";
        this.classClosed = "burger--closed";
        this.eventOpen = new CustomEvent("burgerOpen", { bubbles: true });
        this.eventClose = new CustomEvent("burgerClose", { bubbles: true });
        this.currentState = "closed";
        this.elem = node;
        this.elem.addEventListener('click', this.toggleState);
    }
    open() {
        this.currentState = "opened";
        this.elem.classList.add(this.classOpened);
        this.elem.classList.remove(this.classClosed);
        this.elem.dispatchEvent(this.eventOpen);
    }
    close() {
        this.currentState = "closed";
        this.elem.classList.add(this.classClosed);
        this.elem.classList.remove(this.classOpened);
        this.elem.dispatchEvent(this.eventClose);
    }
    addAction(handler, once = false) {
        this.elem.addEventListener('click', handler, { once });
    }
    removeAction(handler) {
        this.elem.removeEventListener('click', handler);
    }
}
