var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
export let UiTooltip = class UiTooltip extends LitElement {
    constructor() {
        super();
        this.open = false;
        this.isMobile = false;
        const isMobile = window.matchMedia;
        if (!isMobile) {
            this.isMobile = false;
        }
        else {
            const device = isMobile('(pointer:coarse)');
            this.isMobile = device.matches;
        }
    }
    setOpen(fn) {
        requestAnimationFrame(function () {
            requestAnimationFrame(function () {
                fn();
            });
        });
    }
    show() {
        var _a;
        const content = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('[part="content"]');
        if (!content)
            return;
        content.style.opacity = `1`;
        this.setOpen(() => {
            this.open = true;
            content.addEventListener("transitionend", () => (content.style.opacity = ""), {
                once: true,
            });
        });
    }
    hide() {
        var _a;
        const content = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('[part="content"]');
        if (!content)
            return;
        this.setOpen(() => {
            this.open = false;
        });
    }
    toggleMobile() {
        if (!this.isMobile) {
            return;
        }
        this.open ? this.hide() : this.show();
    }
    render() {
        return html `
            <div name="wrapper">
                <slot name="head" @click=${this.toggleMobile} @mouseenter="${this.show}" @mouseleave="${this.hide}"></slot>
                <div part="content">
                    <slot @click=${this.hide} name="content"></slot>
                </div> 
            </div>
		`;
    }
};
UiTooltip.styles = css `
		:host::part(content) {
          position: absolute;
          margin: 0 auto;
          left: 0;
          right: 0;
          opacity: 1;
          transition: opacity 0.3s;
		}
		:host(:not([open]))::part(content) {
			opacity: 0;
		}
	`;
__decorate([
    property({
        type: Boolean,
        reflect: true,
    })
], UiTooltip.prototype, "open", void 0);
__decorate([
    property({
        type: Boolean,
        reflect: true,
    })
], UiTooltip.prototype, "isMobile", void 0);
UiTooltip = __decorate([
    customElement("ui-tooltip")
], UiTooltip);
