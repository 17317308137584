var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { componentRequest } from '../utils/componentRequest';
export const add2Basket = (id, quantity) => __awaiter(void 0, void 0, void 0, function* () {
    const data = new FormData();
    data.append('quantity', quantity.toString());
    data.append('id', id.toString());
    const result = yield componentRequest(data, 'placestart:catalog.card', 'add2Basket');
    if (result.data.STATUS !== 'OK') {
        throw new Error('Ошибка при добавлении в корзину');
    }
    return result;
});
