import { delegate } from "../utils/delegate";
export default class CustomSelect {
    constructor({ container }) {
        this.clickHandler = (e, btn) => {
            if (!btn.dataset.value)
                throw new Error("Не указано значение");
            this.currentValue = btn.dataset.value;
            this.container.dataset.value = this.currentValue;
            this.textContainer.dataset.text = btn.innerText;
            const current = this.container.querySelector('.current');
            if (current)
                current.classList.remove('current');
            btn.classList.add('current');
            this.container.dispatchEvent(new CustomEvent('customSelectChanged', {
                bubbles: true,
                cancelable: true,
                detail: {
                    dropdownID: this.container.id,
                    value: this.currentValue
                }
            }));
        };
        if (!container)
            throw new Error("Контейнер не определён");
        const textContainer = container.querySelector('[data-text]');
        if (!textContainer)
            throw new Error('Нет контейнера для текста');
        this.container = container;
        this.textContainer = textContainer;
        this.currentValue = '';
        delegate('click', '[data-value]', this.clickHandler, this.container);
    }
}
