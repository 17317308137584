export function lazyLoad(element) {
    if (element.nodeName == "PICTURE") {
        const sources = element.querySelectorAll("[data-srcset]");
        sources.forEach((source) => {
            if (source.dataset.srcset) {
                source.srcset = source.dataset.srcset;
            }
        });
        const img = element.querySelector("[data-src]");
        if (img && img.dataset.src)
            img.src = img.dataset.src;
    }
    else {
        if (element.dataset.src) {
            ;
            element.src = element.dataset.src;
        }
        else if (element.dataset.bg) {
            element.style.backgroundImage = `url('${element.dataset.bg}')`;
        }
    }
    element.classList.add("loaded");
}
