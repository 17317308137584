export function getCardDataById(id) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    let cardButton = document.querySelector(`[data-add-to-cart="${id}"]`);
    if (!cardButton) {
        cardButton = document.querySelector(`#add-to-basket`);
    }
    if (!cardButton) {
        cardButton = document.querySelector(`._add-to-basket-${id}`);
    }
    if (!cardButton)
        return;
    const card = cardButton.closest('._card-js');
    if (!card)
        return;
    let name = card.querySelector(`._link-js`);
    let link = (_a = name === null || name === void 0 ? void 0 : name.href) !== null && _a !== void 0 ? _a : '';
    let title = (_b = name === null || name === void 0 ? void 0 : name.textContent) !== null && _b !== void 0 ? _b : '';
    let price = (_d = (_c = card.querySelector(`._price-js`)) === null || _c === void 0 ? void 0 : _c.textContent) !== null && _d !== void 0 ? _d : '';
    let oldPrice = (_f = (_e = card.querySelector(`._old-price-js`)) === null || _e === void 0 ? void 0 : _e.textContent) !== null && _f !== void 0 ? _f : '';
    let img = (_h = (_g = card.querySelector(`._img-js`)) === null || _g === void 0 ? void 0 : _g.srcset) !== null && _h !== void 0 ? _h : '';
    if (!img) {
        img = (_k = (_j = card.querySelector(`._img-src-js`)) === null || _j === void 0 ? void 0 : _j.src) !== null && _k !== void 0 ? _k : '';
    }
    const productProps = [];
    let elementsPropsArray = card.querySelectorAll(`._props-js`);
    elementsPropsArray.forEach((propElem) => {
        var _a;
        productProps.push((_a = propElem === null || propElem === void 0 ? void 0 : propElem.textContent) !== null && _a !== void 0 ? _a : '');
    });
    let quantity = (_m = (_l = card.querySelector(`#product-counter input`)) === null || _l === void 0 ? void 0 : _l.value) !== null && _m !== void 0 ? _m : '1';
    return {
        title,
        link,
        productProps,
        price,
        oldPrice,
        img,
        quantity
    };
}
