"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
const categoriesSlider = () => __awaiter(void 0, void 0, void 0, function* () {
    const sections = document.querySelectorAll('.categories-slider');
    if ((sections === null || sections === void 0 ? void 0 : sections.length) == 0)
        return;
    const { default: Swiper } = yield import('../libs/Swiper');
    sections.forEach(section => {
        const swiper = section.querySelector('.swiper');
        if (!swiper)
            return;
        new Swiper(swiper, {
            slidesPerView: 2,
            speed: 400,
            loop: true,
            breakpoints: {
                // when window width is >= 320px
                320: {
                    slidesPerView: 2,
                },
                // when window width is >= 480px
                480: {
                    slidesPerView: 3,
                },
                // when window width is >= 780px
                780: {
                    slidesPerView: 4
                },
            },
            navigation: {
                prevEl: section.querySelector('.prev'),
                nextEl: section.querySelector('.next')
            },
            pagination: {
                el: section.querySelector('.slider-pagination'),
                clickable: true
            },
            on: {
                init: function (swiper) {
                    let countSlides = swiper.slides.length;
                    for (let i = 0; i < countSlides; i++) {
                        swiper.slides[i].classList.remove("_init-slide");
                    }
                    swiper.navigation.nextEl.classList.remove('swiper-button-hidden');
                    swiper.navigation.prevEl.classList.remove('swiper-button-hidden');
                },
            },
        });
    });
});
categoriesSlider();
