import Burger from "../libs/Burger";
const header = () => {
    const burger = document.querySelector("#header-burger");
    if (!burger)
        return;
    const mobileMenu = document.querySelector("#mobile-menu");
    if (!mobileMenu)
        return;
    const headerBurger = new Burger(burger);
    headerBurger.addAction(() => {
        document.body.classList.toggle("overflow-hidden");
        mobileMenu.classList.toggle("opened");
    });
};
header();
