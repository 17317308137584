var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import ky from 'ky';
export const getCompare = () => __awaiter(void 0, void 0, void 0, function* () {
    const body = new FormData();
    body.append('ajax_action', 'Y');
    try {
        const url = `/catalog/compare/?action=COMPARE&DIFFERENT=N`;
        const result = yield ky.post(url, { body }).json();
        return Promise.resolve(result);
    }
    catch (_a) {
        return Promise.resolve({
            ITEMS: [],
            PROPS: {}
        });
    }
});
