import { persistQueryClient } from '@tanstack/query-persist-client-core';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { queryClient } from "./queryClient";
persistQueryClient({
    queryClient,
    buster: '05.05.2023 09:00',
    maxAge: 1000 * 60 * 60 * 3,
    persister: createSyncStoragePersister({
        storage: localStorage
    })
});
