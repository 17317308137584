var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
class Map {
    constructor({ container, center, zoom }) {
        if (!container)
            throw new Error("Контейнер не определён");
        this.container = container;
        this.createMap(center, zoom);
    }
    static loadAPI(key) {
        if (!this.isLoading && !this.ymapIsReady) {
            this.loader = this.startLoad(key);
            return this.loader;
        }
        else
            return this.loader;
    }
    static startLoad(key) {
        return new Promise((resolve) => {
            this.isLoading = true;
            if (Map.ymapIsReady) {
                this.isLoading = false;
                resolve("loaded");
                return;
            }
            const script = document.createElement("script");
            script.src = `https://api-maps.yandex.ru/3.0/?apikey=${key}&lang=ru_RU`;
            script.addEventListener("load", () => __awaiter(this, void 0, void 0, function* () {
                yield ymaps3.ready;
                Map.ymapIsReady = true;
                this.isLoading = false;
                resolve("loaded");
            }));
            document.body.append(script);
        });
    }
    static createDefaultMarker() {
        const marker = document.createElement("div");
        marker.innerHTML = `
			<svg viewBox="0 0 61 87" class="icon">
				<path d="M61 30.706C61 44.7742 40.0102 75.54 32.8557 85.618C31.6886 87.2621 29.3114 87.2621 28.1443 85.618C20.9898 75.54 0 44.7742 0 30.706C0 13.7475 13.6553 0 30.5 0C47.3447 0 61 13.7475 61 30.706Z" fill="#A7ACCA"/>
				<path d="M29.4253 36.2574V18.8412H19V46.3554H41.9967V36.2574H29.4253Z" fill="#0E0E48"/>
				<path d="M41.999 30.5005H30.8854V34.7056H41.999V30.5005Z" fill="#0E0E48"/>
				<path d="M41.999 18.8412H30.8854V23.0462H41.999V18.8412Z" fill="#0E0E48"/>
				<path d="M41.999 13.0001H30.8854V17.2052H41.999V13.0001Z" fill="#DF0A00"/>
				<path d="M41.999 24.6692H30.8854V28.8743H41.999V24.6692Z" fill="#0E0E48"/>
			</svg>`;
        marker.className = "default-map-marker";
        return marker;
    }
    static createMarkerWidthPopup(marker, popupContent) {
        var _a, _b;
        if (!popupContent) {
            return marker;
        }
        const element = document.createElement('div');
        element.className = 'popup';
        const wrapperElement = document.createElement('div');
        wrapperElement.className = 'popup-wrapper';
        const nameElement = document.createElement('div');
        nameElement.className = 'title';
        nameElement.textContent = (_a = popupContent === null || popupContent === void 0 ? void 0 : popupContent.name) !== null && _a !== void 0 ? _a : '';
        wrapperElement.append(nameElement);
        (_b = popupContent.params) === null || _b === void 0 ? void 0 : _b.forEach((html) => {
            wrapperElement.appendChild(html);
        });
        const closeBtn = document.createElement('div');
        closeBtn.className = 'popup__close';
        closeBtn.textContent = '✖';
        closeBtn.onclick = () => {
            element.style.display = 'none';
        };
        element.append(wrapperElement, closeBtn);
        const container = document.createElement('div');
        container.className = 'marker-container';
        container.append(marker, element);
        return container;
    }
    createMap(center = [37.617635, 55.755814], zoom = 15) {
        this.ymap = new ymaps3.YMap(this.container, {
            location: {
                center,
                zoom,
            },
        }, [
            new ymaps3.YMapDefaultSchemeLayer({}),
            new ymaps3.YMapDefaultFeaturesLayer({ zIndex: 1800 }),
        ]);
    }
    setLocation(center, zoom = 15) {
        var _a;
        if (!this.ymap)
            return;
        (_a = this.ymap) === null || _a === void 0 ? void 0 : _a.setLocation({
            center,
            zoom,
        });
    }
    addMarker(coordinates, content, props) {
        if (!this.ymap)
            return;
        const marker = new ymaps3.YMapMarker(Object.assign({ coordinates, draggable: false }, props), content);
        this.ymap.addChild(marker);
    }
    addMarkers(coordinates, content) {
        coordinates.forEach((coords) => {
            this.addMarker(coords, content(coords));
        });
    }
}
Map.ymapIsReady = false;
Map.isLoading = false;
export default Map;
