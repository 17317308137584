var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { delegate } from "../utils/delegate";
import { add2Basket } from "./add2Basket";
import { queryClient } from "../vue/queryClient";
import { add2Compare } from "./add2Compare";
import { add2Favourite } from "./add2Favourite";
import { copyOrder } from "./copyOrder";
import { componentRequest } from "../utils/componentRequest";
import { initAdd2BasketModal } from "../vue/components/FancyboxTemplates";
delegate('click', '[data-add-to-cart]', (e, btn) => __awaiter(void 0, void 0, void 0, function* () {
    const id = btn.dataset.addToCart;
    if (!id)
        return;
    const text = btn.innerText;
    btn.disabled = true;
    btn.innerText = '...';
    yield add2Basket(parseInt(id), 1);
    queryClient.invalidateQueries({
        queryKey: ['basketData']
    });
    yield initAdd2BasketModal(id);
    btn.innerText = 'Добавлено';
    setTimeout(() => {
        btn.disabled = false;
        btn.innerText = text;
    }, 3500);
}));
delegate('click', '[data-add-to-compare]', (e, btn) => __awaiter(void 0, void 0, void 0, function* () {
    const id = btn.dataset.addToCompare;
    if (!id)
        return;
    yield add2Compare(parseInt(id));
    queryClient.invalidateQueries({
        queryKey: ['compareData']
    });
    btn.classList.add('active');
}));
delegate('click', '[data-add-to-favourite]', (e, btn) => __awaiter(void 0, void 0, void 0, function* () {
    const id = btn.dataset.addToFavourite;
    if (!id)
        return;
    yield add2Favourite(parseInt(id));
    queryClient.invalidateQueries({
        queryKey: ['favouritesData']
    });
    btn.classList.add('active');
}));
delegate('click', '[data-copy-order]', (e, btn) => __awaiter(void 0, void 0, void 0, function* () {
    const id = btn.dataset.copyOrder;
    if (!id)
        return;
    const { data } = yield copyOrder(parseInt(id));
    if (data.STATUS == 'OK') {
        alert(`Создан заказ №${data.NEW_ORDER_ID}`);
    }
    else {
        alert(data.MESSAGE);
    }
}));
delegate('click', '[data-delete-all-from-favourite]', (e, btn) => __awaiter(void 0, void 0, void 0, function* () {
    const result = yield componentRequest(null, 'placestart:favourite.products', 'deleteAll');
    console.log(result);
    if (result.data.STATUS = 'OK') {
        queryClient.invalidateQueries({
            queryKey: ['favouritesData']
        });
    }
}));
const productCardSlider = () => __awaiter(void 0, void 0, void 0, function* () {
    document.querySelectorAll('.mini-slider').forEach((element, index) => {
        if (!element.classList.contains('init-slider')) {
            element.classList.add('init-slider');
            const slides = element.querySelectorAll('.mini-slider__slide');
            const slideLength = slides.length;
            const dotsContainer = element.querySelector('.mini-slider__dots');
            if (slideLength > 1) {
                for (let i = 0; i < slideLength; i++) {
                    let dot = document.createElement('li');
                    dot.className = 'mini-slider__dot';
                    dotsContainer.appendChild(dot);
                }
                const firstImg = slides[0].querySelector('.mini-slider__img');
                firstImg.style.opacity = '1';
                dotsContainer.children[0].classList.add('mini-slider__dot--active');
                slides.forEach((slide, index) => {
                    slide.addEventListener('touchstart', handleInteraction);
                    slide.addEventListener('mouseover', handleInteraction);
                    slide.addEventListener('mouseleave', handleMouseLeave); // Добавляем обработчик mouseleave
                    function handleInteraction(event) {
                        if (window.innerWidth > 991) {
                            const thisOp = Array.from(element.querySelectorAll('.mini-slider__slide')).indexOf(slide);
                            element.querySelectorAll('.mini-slider__img').forEach((img) => img.style.opacity = '0');
                            slide.querySelector('.mini-slider__img').style.opacity = '1';
                            Array.from(dotsContainer.children).forEach((dot) => dot.classList.remove('mini-slider__dot--active'));
                            dotsContainer.children[thisOp].classList.add('mini-slider__dot--active');
                            event.stopPropagation();
                            event.preventDefault();
                        }
                    }
                    function handleMouseLeave() {
                        element.querySelectorAll('.mini-slider__img').forEach((img) => img.style.opacity = '0');
                        slides[0].querySelector('.mini-slider__img').style.opacity = '1';
                        Array.from(dotsContainer.children).forEach((dot) => dot.classList.remove('mini-slider__dot--active'));
                        dotsContainer.children[0].classList.add('mini-slider__dot--active');
                    }
                });
                element.querySelectorAll('.mini-slider__button').forEach((button) => {
                    button.addEventListener('click', (event) => {
                        event.preventDefault();
                        const currentVisibleSlide = Array.from(element.querySelectorAll('.mini-slider__img')).find((img) => img.style.opacity === '1').closest('.mini-slider__slide');
                        const slidesCount = slides.length;
                        element.querySelectorAll('.mini-slider__img').forEach((img) => img.style.opacity = '0');
                        Array.from(dotsContainer.children).forEach((dot) => dot.classList.remove('mini-slider__dot--active'));
                        if (button.classList.contains('mini-slider__button--prev')) {
                            let prevSlideIndex = Array.from(slides).indexOf(currentVisibleSlide) - 1;
                            if (prevSlideIndex < 0) {
                                prevSlideIndex = slidesCount - 1;
                            }
                            element.querySelectorAll('.mini-slider__img')[prevSlideIndex].style.opacity = '1';
                            dotsContainer.children[prevSlideIndex].classList.add('mini-slider__dot--active');
                        }
                        if (button.classList.contains('mini-slider__button--next')) {
                            let nextSlideIndex = Array.from(slides).indexOf(currentVisibleSlide) + 1;
                            if (nextSlideIndex >= slidesCount) {
                                nextSlideIndex = 0;
                            }
                            element.querySelectorAll('.mini-slider__img')[nextSlideIndex].style.opacity = '1';
                            dotsContainer.children[nextSlideIndex].classList.add('mini-slider__dot--active');
                        }
                    });
                });
            }
        }
    });
});
productCardSlider();
