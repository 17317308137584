"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
const initGetPriceForm = () => __awaiter(void 0, void 0, void 0, function* () {
    const container = document.querySelector('[data-get-price-form]');
    if (!container || !container.dataset.getPriceForm)
        return;
    const { createApp } = yield import('vue');
    const { default: GetPriceForm } = yield import('./GetPriceForm.vue');
    const app = createApp(GetPriceForm, JSON.parse(container.dataset.getPriceForm));
    app.mount(container);
});
initGetPriceForm();
