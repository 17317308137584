import CustomSelect from "../libs/CustomSelect";
function sortSelect() {
    const container = document.querySelector('#sort-dropdown');
    if (!container)
        return;
    new CustomSelect({ container });
}
sortSelect();
document.addEventListener('JCSmartFilterFetched', sortSelect);
function adaptiveSortSelect() {
    const sort = document.querySelector('#sort-dropdown');
    if (!sort)
        return;
    if (matchMedia('(max-width: 1279px)').matches) {
        const sidebar = document.querySelector('.sidebar');
        sidebar === null || sidebar === void 0 ? void 0 : sidebar.insertAdjacentElement('afterbegin', sort);
    }
}
const filtersForm = document.querySelector('#filters-form');
if (filtersForm) {
    adaptiveSortSelect();
    window.addEventListener('resize', adaptiveSortSelect);
}
