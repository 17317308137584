import { startWatch } from "../utils/startWatch";
function toTop() {
    if (matchMedia('(max-width: 1279px)').matches)
        return;
    const button = document.querySelector('#to-top');
    if (!button)
        return;
    const top = document.createElement('div');
    top.id = 'top';
    document.body.prepend(top);
    const darkElements = document.querySelectorAll('[data-dark-bg]');
    startWatch({
        elements: darkElements,
        handler(entry) {
            entry.isIntersecting ? button.classList.remove('dark') : button.classList.add('dark');
        },
        margin: "-61% 0% -15%",
        threshold: 0,
        unobserve: false
    });
}
toTop();
