var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import { getCardDataById } from "../../getCardDataById";
export const initAdd2BasketModal = (id) => __awaiter(void 0, void 0, void 0, function* () {
    const container = document.querySelector('#modal-add2basket');
    if (!container)
        return;
    const { createApp } = yield import('vue');
    const { default: Add2BasketTemplate } = yield import('./Add2BasketTemplate.vue');
    const params = getCardDataById(id);
    if (!params)
        return;
    const app = createApp(Add2BasketTemplate, Object.assign({}, params));
    app.mount(container);
    let fancyContent = [{ src: "#add2basket-template", type: "inline" }];
    Fancybox.show(fancyContent);
});
