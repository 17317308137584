var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import ky from 'ky';
export const updateBasket = (payload) => __awaiter(void 0, void 0, void 0, function* () {
    const data = new FormData();
    payload === null || payload === void 0 ? void 0 : payload.forEach(param => {
        data.append(param.key, param.value);
    });
    data.append('basketAction', payload ? 'recalculateAjax' : 'refreshAjax');
    data.append('via_ajax', 'N');
    data.append('site_template_id', 'liftrus');
    data.append('site_id', window.siteID);
    data.append('sessid', window.sessid);
    const result = yield ky.post('/bitrix/components/bitrix/sale.basket.basket/ajax.php', {
        body: data
    }).json();
    return Promise.resolve({
        items: result.BASKET_DATA.BASKET_ITEM_RENDER_DATA,
        summ: result.BASKET_DATA.allSum_FORMATED
    });
});
