import { QueryObserver } from "@tanstack/vue-query";
import { queryClient } from "../vue/queryClient";
import { getCompare } from "../vue/compareState";
const headerCompare = () => {
    const compareContainers = document.querySelectorAll("[data-compare] [data-count]");
    if (!compareContainers)
        return;
    const observer = new QueryObserver(queryClient, {
        queryKey: ["compareData"],
        queryFn: () => getCompare(),
    });
    observer.subscribe((result) => {
        if (result.status != "success")
            return;
        compareContainers.forEach((container) => {
            container.innerText = result.data.ITEMS.length > 0 ? result.data.ITEMS.length.toString() : "";
        });
    });
};
headerCompare();
