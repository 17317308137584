import { delegate } from "../utils/delegate";
const mobileMenu = () => {
    const menu = document.querySelector('.mobile-menu');
    if (!menu)
        return;
    delegate('click', '.mobile-menu .item.has-submenu>.link', (e, target) => {
        e.preventDefault();
        const parent = target.closest('.item');
        parent === null || parent === void 0 ? void 0 : parent.classList.add('open');
    }, menu);
    delegate('click', '.mobile-menu .item.has-submenu .back', (e, target) => {
        const parent = target.closest('.item');
        parent === null || parent === void 0 ? void 0 : parent.classList.remove('open');
    });
};
mobileMenu();
