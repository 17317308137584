var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import ky from 'ky';
export const add2Compare = (id) => __awaiter(void 0, void 0, void 0, function* () {
    const result = yield ky.post(`/catalog/compare/?action=ADD_TO_COMPARE_LIST&id=${id}&ajax_action=Y`).json();
    if (result.STATUS !== 'OK') {
        throw new Error('Ошибка при добавлении в сравнение');
    }
    return result;
});
